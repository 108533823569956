import React, { useEffect, useState } from 'react';
import PriceTierSectionStyled from './PriceTierSection.styled';
import { useTranslation } from 'react-i18next';
import { WebstorePriceTiers, PriceTierTile, PearlError } from 'framework';
import { useFormContext } from 'react-hook-form';

const tierSelections = [
    {
        id: 'price-tier-budget-friendly',
        value: WebstorePriceTiers.BUDGETFRIENDLYVALUE,
        title: 'Forms.PriceTier.BudgetFriendly',
        body: 'Forms.PriceTier.BoxBody.BudgetFriendly',
        monetarySymbol: 'Forms.PriceTier.MonetarySymbols.BudgetFriendly',
    },
    {
        id: 'price-tier-standard',
        value: WebstorePriceTiers.STANDARDVALUE,
        title: 'Forms.PriceTier.Standard',
        body: 'Forms.PriceTier.BoxBody.Standard',
        monetarySymbol: 'Forms.PriceTier.MonetarySymbols.Standard',
    },
    {
        id: 'price-tier-premium',
        value: WebstorePriceTiers.PREMIUMVALUE,
        title: 'Forms.PriceTier.Premium',
        body: 'Forms.PriceTier.BoxBody.Premium',
        monetarySymbol: 'Forms.PriceTier.MonetarySymbols.Premium',
    },
    {
        id: 'price-tier-luxury',
        value: WebstorePriceTiers.LUXURYVALUE,
        title: 'Forms.PriceTier.Luxury',
        body: 'Forms.PriceTier.BoxBody.Luxury',
        monetarySymbol: 'Forms.PriceTier.MonetarySymbols.Luxury',
    },
];

const PriceTierSection = ({ defaultTierValue }) => {
    const formFieldName = 'SelectedPriceTier';

    const { t } = useTranslation();
    const { watch, clearErrors } = useFormContext();

    const [selectedTier, setSelectedTier] = useState(Number(defaultTierValue));

    const priceTierValue = watch('SelectedPriceTier');

    useEffect(() => {
        if (priceTierValue) {
            clearErrors('SelectedPriceTier');
        }
    }, [priceTierValue]);

    return (
        <PriceTierSectionStyled>
            <div className="price-tier-wrapper">
                <div className="boxes">
                    {tierSelections.map((tier) => {
                        return (
                            <PriceTierTile
                                titleText={t(tier.title)}
                                bodyText={t(tier.body)}
                                monetarySymbol={t(tier.monetarySymbol)}
                                tierId={tier.id}
                                tierValue={tier.value}
                                formFieldName={formFieldName}
                                key={tier.id}
                                isChecked={selectedTier === Number(tier.value)}
                                onClick={() => setSelectedTier(Number(tier.value))}
                            />
                        );
                    })}
                    <PearlError name="SelectedPriceTier" />
                </div>
            </div>
        </PriceTierSectionStyled>
    );
};

export { PriceTierSection };
