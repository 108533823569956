import { IconName, PearlIcon } from 'dbi-pearl-ui-kit';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { WebstoreService } from 'vendor/lib/apis/WebstoreService';
import { useTranslation } from 'react-i18next';
import { PearlDropzone } from 'shared/components/PearlDropzone/PearlDropzone';
import { WebstoreGalleryEditStyled } from './WebstoreGalleryEdit.styled';
import { useWebstoreContext } from '../../../contexts/WebstoreContext/WebstoreContext';
import { validateFileSize, trimFiles } from './WebstoreGalleryEditFuncs';
import { usePearlToast, NewTabLink } from 'framework';
import { Image } from '../../Image/Image';
import { Draggable } from './Draggable';
import { EXTERNAL_LINKS } from '../../../../shared/constants/navigation';

const MAX_FILE_SIZE = 15000000;
const EXCEEDS_MAX_IMAGES = 'Forms.MediaUpload.ExceedsFiveImages';
const EXCEEDS_MAX_FILE_SIZE = 'Forms.MediaUpload.ExceedsMaxSize';
const UNSUPPORTED_FILE_TYPE = 'Forms.MediaUpload.UnsupportedFileType';
const WAIT_FOR_UPLOAD_TO_FINISH = 'Forms.MediaUpload.WaitForUploadToFinish';

const WebstoreGalleryEditBase = (props) => {
    const { imageSelected, parentErrors } = props;
    const { webstore } = useWebstoreContext();
    const { toastSuccess } = usePearlToast();
    const { t } = useTranslation();
    const [MAX_IMAGES, setMaxImages] = useState();
    const [MinMaxMessage, SetMinMaxMessage] = useState('');
    const [NumUploadMessage, SetNumUploadMessage] = useState('');
    const [pendingFiles, setPendingFiles] = useState([]);
    const [errorMessages, setErrorMessages] = useState(
        parentErrors?.length ? [...parentErrors] : []
    );
    const [stepValidationClass, setStepValidationClass] = useState('');

    const webstoreApi = WebstoreService();

    useEffect(() => {
        const storePlan = webstore.SelectedPlan;
        if (storePlan === 'planone') {
            setMaxImages(5);
            SetMinMaxMessage('MinMax');
            SetNumUploadMessage('NumUploads');
        } else {
            setMaxImages(null);
            SetMinMaxMessage('PaidMinMax');
            SetNumUploadMessage('PaidSubsNumUploads');
        }
    }, []);

    const handleUpload = async (droppedFiles, rejectedFiles) => {
        setErrorMessages([]);
        const errors = [];

        if (pendingFiles.length > 0) {
            errors.push({
                text: WAIT_FOR_UPLOAD_TO_FINISH,
            });
            setErrorMessages(errors);
            return;
        }

        rejectedFiles.forEach((item) => {
            errors.push({
                text: UNSUPPORTED_FILE_TYPE,
                filename: item.file.name,
            });
        });

        const [validFiles, invalidFiles] = validateFileSize(droppedFiles, MAX_FILE_SIZE);
        invalidFiles?.forEach((file) => {
            errors.push({ text: EXCEEDS_MAX_FILE_SIZE, filename: file.name });
        });

        const acceptedFiles = trimFiles(images, validFiles, MAX_IMAGES);
        if (acceptedFiles?.length < validFiles?.length) {
            errors.push({ text: EXCEEDS_MAX_IMAGES, max: MAX_IMAGES });
        }
        setErrorMessages(errors);
        setPendingFiles(
            acceptedFiles?.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                })
            )
        );
        const uploads = [];
        acceptedFiles?.forEach((file) => {
            uploads.push(webstoreApi.uploadImage(webstore.Id, file));
        });

        await Promise.all(uploads);
        await reloadWebstore();

        if (webstore?.Media?.images?.length > 0) {
            setStepValidationClass('');
            toastSuccess(t('Forms.MediaUpload.UploadSuccess'));
        }
    };

    const previews = pendingFiles.map((file, index) => (
        <div className="preview" key={`file${index}`}>
            <div className="loader">
                <PearlIcon iconName={IconName.LOADER} />
            </div>
            <img alt="preview" src={file.preview} />
        </div>
    ));

    const reloadWebstore = async () => {
        const res = await webstoreApi.getWebstoreById(webstore.Id, { removeInvalidImgSrc: false });
        const updatedWebstore = res.data;
        setPendingFiles([]);
        webstore.setFromApi(updatedWebstore);
        imageSelected(null);
    };

    const move = async (sourceId, targetId) => {
        let images = webstore.Media.images;

        const source = images.find((x) => x.id === sourceId);
        const target = images.find((x) => x.id === targetId);
        const targetIndex = images.indexOf(target);

        images = images.filter((x) => x !== source);
        images.splice(targetIndex, 0, source);

        webstore.Media.images = images;

        const payload = images.map((x, i) => ({ id: x.id, ordinal: i }));
        const updatedWebstore = await webstoreApi.reorderImages(webstore.Id, payload);

        if (updatedWebstore) {
            webstore.setFromApi(updatedWebstore);
        }
    };

    const images =
        webstore?.Media?.images?.map((image, index) =>
            image.isCoverPhoto ? (
                <Image
                    key={`image${image.id}`}
                    image={image}
                    imageSelected={imageSelected}
                    imageDeleted={async () => {
                        await reloadWebstore();
                        toastSuccess(t('Forms.MediaUpload.DeleteSuccess'));
                    }}
                />
            ) : (
                <Draggable key={`image${image.id}`} id={image.id} move={move}>
                    <Image
                        image={image}
                        imageSelected={imageSelected}
                        imageDeleted={async () => {
                            await reloadWebstore();
                            toastSuccess(t('Forms.MediaUpload.DeleteSuccess'));
                        }}
                    />
                </Draggable>
            )
        ) || [];

    const showErrors = errorMessages?.map((message, index) => (
        <p className="p2 error" key={`error${index}`}>
            {t(message.text, { filename: message.filename })}
        </p>
    ));

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks
        pendingFiles.forEach((file) => URL.revokeObjectURL(file.preview));
    }, [pendingFiles]);

    useEffect(() => {
        if (parentErrors) {
            setErrorMessages(parentErrors);
        }
    }, [parentErrors, setErrorMessages]);

    return (
        <WebstoreGalleryEditStyled>
            <label className="addLabel">
                <b>{t('Forms.MediaUpload.AddMedia')}</b>
            </label>
            {webstore?.Media?.images?.length ? (
                <p className="p2" data-testid="instructions">
                    {t(`Forms.MediaUpload.${NumUploadMessage}`, {
                        uploads: webstore.Media.images.length,
                        max: MAX_IMAGES,
                    })}
                </p>
            ) : (
                <p className={`p2 ${stepValidationClass}`}>
                    {t(`Forms.MediaUpload.${MinMaxMessage}`)}
                </p>
            )}
            <div data-testid="errors">{showErrors}</div>
            <div>
                <div className="imageContainer">
                    {MAX_IMAGES !== null && images?.length + pendingFiles.length < MAX_IMAGES && (
                        <div className="dropPlaceholder">
                            <PearlDropzone handleUpload={handleUpload} />
                        </div>
                    )}
                    {MAX_IMAGES === null && (
                        <div className="dropPlaceholder">
                            <PearlDropzone handleUpload={handleUpload} />
                        </div>
                    )}
                    {images}
                    {previews}
                </div>
            </div>
            <div className="legalInfo">
                <b>{t('Forms.MediaUpload.LegalTitle')}</b>
                {t('Forms.MediaUpload.LegalBeforeLink')}
                <NewTabLink link={EXTERNAL_LINKS.termsAndConditions}>
                    {t('Forms.MediaUpload.LegalTerms')}
                </NewTabLink>
                {t('Forms.MediaUpload.LegalAfterLink')}
            </div>
        </WebstoreGalleryEditStyled>
    );
};

WebstoreGalleryEditBase.defaultProps = {
    imageSelected: () => null,
};

const WebstoreGalleryEdit = observer(WebstoreGalleryEditBase);

export { WebstoreGalleryEdit };
