import styled from 'styled-components';
import checkmark from '../../../../assets/logos/checkmark.svg';

const Tile = styled.div`
    border: ${({ isSelected }) => (isSelected ? '2px solid #E8184C' : '2px solid #CFD0D0')};
    border-radius: 20px;
    padding-top: 53px;
    padding-bottom: 16px;
    width: 276px;
    text-align: left;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    transition: max-height 0.3s ease;

    @media (max-width: 815px) {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .saveButton {
        width: 100%;
        color: #e8184c;
        background: white;
        border: 2px solid;
        label {
            b {
                text-transform: none;
                font-weight: 700;
                font-size: 14px;
                text-align: center;
            }
        }
    }
`;

const TileBody = styled.div`
    display: flex;
    flex-direction: column;
    padding-right: 20px;
    padding-left: 20px;
`;

const Title = styled.h4`
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    font-size: 18px;
    margin: 0px;
    text-transform: none;
    @media (max-width: 815px) {
        margin-top: ${({ isPopular }) => !isPopular && '13px'};
        padding-top: ${({ isPopular }) => isPopular && '25px'};
        font-size: 24px;
    }
`;

const Price = styled.h3`
    font-weight: 700;
    font-size: 32px;
    margin: 0px;
    line-height: 0px;
`;
const Duration = styled.h4`
    font-weight: 700;
    font-size: 18px;
    line-height: 0px;
`;

const Period = styled.div`
    margin-top: 10px;
    margin-bottom: 15px;
    font-weight: 300;
    font-size: 12px;
`;

const Description = styled.p`
    font-weight: 700;
    font-family: 'Objektiv Mk2 Bold';
    font-size: 14px;
    margin-bottom: 0px;
    margin-top: 0px;
`;

const Benefits = styled.ul`
    list-style: none;
    padding: 0;
    text-align: left;
    font-size: 14px;
    font-weight: 300;
    margin-top: 0px;

    .benefitItem {
    }
`;

const BenefitItem = styled.div`
    margin: 15px 0px 0px 0px;
    display: inline-flex;
    align-items: flex-start;
`;
const Checkmark = styled.span`
    content: url(${checkmark});
    width: 14.636px;
    height: 16px;
    flex-shrink: 0;
    padding-right: 3px;
    padding-top: 2.67px;
`;

const Benefit = styled.li`
    font-size: 14px;
`;

const TrialLink = styled.a`
    color: red;
    text-decoration: underline;
    cursor: pointer;
    margin-left: 5px;
`;

const Highlight = styled.span`
    color: red;
`;

const PopularBadge = styled.div`
    position: absolute;
    top: 0px;
    background-color: #e8184c;
    color: white;
    padding-top: 6px;
    padding-bottom: 6px;
    font-weight: bold;
    font-size: 0.8em;
    text-align: center;
    width: 276px;
    @media (max-width: 815px) {
        top: 0px;
    }
`;

const ToggleIcon = styled.span`
    font-size: 1.5em;
    transition: transform 0.2s ease;
    color: black;

    @media (min-width: 769px) {
        display: none;
    }
`;

const HiddenRadio = styled.input`
    display: none;
`;

const PriceContainer = styled.div`
    display: flex;
    align-items: baseline;
    margin: 16px 0px;
`;

const PlanInfo = styled.div`
    color: #000;
    font-family: 'Objektiv Mk2';
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px;
    padding-right: 15px;
    margin-top: 15px;
`;

export {
    Tile,
    Title,
    Price,
    Period,
    Description,
    Benefits,
    Benefit,
    TrialLink,
    Highlight,
    PopularBadge,
    ToggleIcon,
    HiddenRadio,
    TileBody,
    Duration,
    PriceContainer,
    PlanInfo,
    Checkmark,
    BenefitItem,
};
