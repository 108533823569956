import { APP_ROUTES } from 'shared/constants/navigation';
import { COMPLETE_STEP } from 'vendor/constants/WebstoreSetupSteps';

const RequireWebstoreNotComplete = (context) => {
    if (context.store.webstore.CurrentSetupStep === COMPLETE_STEP) {
        return APP_ROUTES.vendor.dashboard;
    }
    return 'next';
};

export default RequireWebstoreNotComplete;
