import styled from 'styled-components';
const CheckoutFormStyled = styled.div`
    display: flex;
    width: 100%;
    align-items: flex-start;
    padding-right: 24px;
    @media (max-width: 815px) {
        width: 100%;
        padding-right: 16px;
    }

    .cardContainer {
        display: flex;
        flex-direction: column;
        gap: 30px;
        width: 432px;
        @media (max-width: 815px) {
            padding-right: 0px;
            width: 100%;
        }
    }
    .card_input {
        width: 100%;
        font-size: 14px;
    }
    .CountryRegion-select {
        font-size: 14px;
        color: rgb(140, 140, 140);
    }
    .CountryRegion-select.is-filled {
        color: black !important;
    }

    .card {
        width: 100%;
        box-sizing: border-box;
        border: #ddd solid 1px;
        padding: 16px;
        outline: none;
        font-size: 14px;
        font-weight: light;
        height: 50px;
    }
    .StripeElement--complete {
        border-color: black;
    }
    .error-message {
        color: red;
        font-size: 12px;
        padding-top: 8px;
        padding-left: 16px;
    }

    .StripeElement--invalid {
        border-color: red;
    }
`;

export default CheckoutFormStyled;
