import * as Yup from 'yup';
import i18n from 'i18n';

const PriceTierSchemaWrapper = () => {
    const priceTierFormSchema = Yup.object().shape({
        SelectedPriceTier: Yup.number()
            .nullable()
            .transform((value, originalValue) =>
                originalValue === null || originalValue === '' ? null : Number(originalValue)
            )
            .required(i18n.t('Forms.PriceTier.selectPriceTier')),
    });

    return priceTierFormSchema;
};

export { PriceTierSchemaWrapper };
