import styled from 'styled-components';

const VendorBusinessDetailsFormStyledNew = styled.form`
    display: flex;
    height: 100%;
    .detailsContainer {
        margin-bottom: 80px;
        @media (min-width: 815px) {
            max-width: 888px;
            margin-bottom: 0px;
        }
    }

    .addressLines > div {
        margin-top: 5px;
    }
    .websiteField {
        margin-top: 0px;
    }

    .is-invalid {
        background-color: rgba(253, 243, 243, 1);
        .p4 {
            color: rgba(222, 23, 23, 1);
        }
    }
    .pearl-error {
        font-size: 12px;
    }
    // .is-focused {
    //     border-color: black !important;
    // }
`;

export { VendorBusinessDetailsFormStyledNew };
