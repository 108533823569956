import React, { useEffect } from 'react';
import { VendorAccountSetupStepFooter } from 'framework';
import { VendorAccountSetupStepLayout } from 'framework';
import { useWebstoreContext } from 'vendor/contexts/WebstoreContext/WebstoreContext';
import { useWebstoreSetupWizard } from 'vendor/components/WebstoreSetup/NewWebstoreSetupContext';
import { NewVendorWebstoreSetupHeader } from '../../NewVendorWebstoreSetupHeader';
import SuccessPage from 'framework/components/Webstore/SuccessPage/SuccessPage';
import { WelcomeTitle } from 'framework/components/Webstore/SuccessPage/SuccessPage.Styled';
import { Container, Email, Text } from './VendorPaymentSuccess.styled';
import { useTranslation } from 'react-i18next';
import { maskEmail } from 'shared/utils/utils';
import { useAuthUserContext } from 'contexts/AuthUserContext';
import { useTealium, buildWebstoreSetupStepPageData } from 'Tealium';
import PearlForm from 'vendor/components/forms/PearlForm';
import { StorageService } from 'lib/StorageService';
import { VendorService } from 'vendor/lib/apis/VendorService';
import { useStore } from 'stores/Store';

const formName = 'VendorPaymentSuccess';
export const Footer = ({ handleSubmit, isLetsGo, isSubmit, handleContinue }) => {
    return (
        <VendorAccountSetupStepFooter
            progress={''}
            isSubmit={isSubmit}
            isLetsGo={isLetsGo}
            handleSubmit={handleSubmit}
            hideStepCount={false}
            showBack={false}
            handleContinue={handleContinue}
        />
    );
};

const VendorPaymentSuccess = () => {
    const { t } = useTranslation();
    const { authUser } = useAuthUserContext();
    const { link, view } = useTealium();
    const email = authUser.Email;
    const userName = authUser.Username;
    const { webstore } = useWebstoreContext();
    const vendorService = VendorService();
    const { onSubmit, currentStepCount, totalSteps } = useWebstoreSetupWizard();
    const storageService = StorageService();
    const { vendor } = useStore();
    useEffect(() => {
        const stepName = t(`Forms.${formName}.StepName`);
        const tealiumData = buildWebstoreSetupStepPageData(
            stepName,
            webstore,
            currentStepCount,
            totalSteps
        );

        view(tealiumData);
    }, []);

    useEffect(() => {
        storageService.remove('stripeData');
    }, []);

    const getVendorPlanData = async () => {
        const updatedVendor = await vendorService.getVendorByUsername(userName);
        if (updatedVendor) {
            vendor.updateVendor(updatedVendor);
        }
    };

    const defaultValue = webstore.PaymentSuccess;
    const formOptions = {
        mode: 'onChange',
        defaultValues: { PaymentSuccess: defaultValue },
    };
    const SuccessMessage = () => {
        return (
            <Container>
                <WelcomeTitle data-testid="welcome-title">
                    {t(`Forms.${formName}.SuccessTitle`)}
                </WelcomeTitle>
                <div>
                    <Text data-testid="welcome-subtitle-one">
                        {t(`Forms.${formName}.SuccessSubtitleOne`)}
                        <Email data-testid="welcome-email">{maskEmail(email)}</Email>
                    </Text>
                </div>
                <Text data-testid="welcome-subtitle-two">
                    {t(`Forms.${formName}.SuccessSubtitleTwo`)}
                </Text>
            </Container>
        );
    };

    const handleContinue = () => {
        getVendorPlanData();
    };
    const handleOnSubmit = (data) => {
        const tealiumData = {
            event_name: 'setup-step-advance-click',
            setup_step_number: 1,
            setup_step_total_step_count: totalSteps,
            setup_step_name: t(`Forms.${formName}.StepName`),
            setup_step_form_data: data,
            setup_step_patch_data: data,
            webstore_id: webstore.Id,
        };

        link(tealiumData);
        onSubmit(data);
    };

    return (
        <PearlForm
            formName={formName}
            formOptions={formOptions}
            onSubmit={(data) => handleOnSubmit(data)}
        >
            <VendorAccountSetupStepLayout
                header={
                    <NewVendorWebstoreSetupHeader
                        stepName={formName}
                        hideStepCount={true}
                        currentStepCount={currentStepCount}
                        totalSteps={totalSteps}
                    />
                }
                formComponent={
                    <SuccessPage>
                        <SuccessMessage />
                    </SuccessPage>
                }
                footer={<Footer isLetsGo={true} handleContinue={handleContinue} />}
            />
        </PearlForm>
    );
};

export { VendorPaymentSuccess };
