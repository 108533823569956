import * as Yup from 'yup';
import i18n from 'i18n';

const CheckoutFormSchemaWrapper = () => {
    const CheckoutFormSchema = Yup.object()
        .shape({
            CardholderName: Yup.string().required(
                i18n.t('Forms.Validation.FieldRequired', {
                    field: i18n.t('Forms.StripeCheckout.CardholderNameLabel'),
                })
            ),
            CountryRegion: Yup.string().required(
                i18n.t('Forms.Validation.FieldRequired', {
                    field: i18n.t('Forms.StripeCheckout.CountryRegion'),
                })
            ),
            Billing: Yup.string().required(i18n.t('Forms.StripeCheckout.BillingValidation')),
        })
        .required();
    return CheckoutFormSchema;
};

export { CheckoutFormSchemaWrapper };
