import { CardElement } from '@stripe/react-stripe-js';
import countryOptions from 'vendor/constants/select-options/countries';
import CheckoutFormStyled from './CheckoutForm.styled';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'dbi-pearl-ui-kit';
import credit from 'assets/credit-cards-group.png';
import { TextInput } from 'dbi-pearl-ui-kit';
import styled from 'styled-components';
import { PearlError } from 'framework';
import { useState } from 'react';
const CheckoutElementContainer = styled.div`
    display: flex;
    width: 432px;
    flex-direction: column;
    gap: 16px;
    flex-shrink: 0;
    @media (max-width: 815px) {
        width: 100%;
    }
`;

const CheckoutLabel = styled.p`
    font-family: Objektiv Mk2 Bold;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: 15px; /* 136.364% */
    letter-spacing: 1.65px;
    text-transform: uppercase;
    margin: 0px;
    color: ${(props) => props.theme.colors.primary.base};
`;

export default function CheckoutForm() {
    const { t } = useTranslation();
    const [errorMessage, setErrorMessage] = useState('');
    return (
        <CheckoutFormStyled>
            <div className="cardContainer">
                <CheckoutElementContainer>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <CheckoutLabel>{t(`Forms.StripeCheckout.CardholderName`)}</CheckoutLabel>
                        <div className="imageWrapper">
                            <img alt="credit-cards" src={credit} width="131px"></img>
                        </div>
                    </div>
                    <TextInput
                        formFieldName="CardholderName"
                        className="card_input"
                        id="cardholder-name"
                    ></TextInput>
                    <PearlError name="CardholderName" />
                </CheckoutElementContainer>
                <CheckoutElementContainer data-testid="stripe-card">
                    <CardElement
                        className="card"
                        options={{
                            style: {
                                base: {
                                    '::placeholder': {
                                        color: 'rgb(140, 140, 140)',
                                        fontFamily: 'Poppins',
                                        fontSize: '13px',
                                        fontWeight: 300,
                                    },
                                },
                            },
                        }}
                        onChange={(event) => {
                            if (event.error) {
                                setErrorMessage(event.error.message);
                            } else {
                                setErrorMessage('');
                            }
                        }}
                    ></CardElement>
                    {errorMessage && <div className="error-message">{errorMessage}</div>}
                </CheckoutElementContainer>
                <CheckoutElementContainer>
                    <Dropdown
                        formFieldName="CountryRegion"
                        id="CountryRegion"
                        providedLabel={'Select country or region'}
                    >
                        {countryOptions.map((country) => (
                            <option key={country.value} value={country.value}>
                                {country.label}
                            </option>
                        ))}
                    </Dropdown>
                    <PearlError name="CountryRegion" />
                </CheckoutElementContainer>
            </div>
        </CheckoutFormStyled>
    );
}
