import { WebstoreAddressService } from '../../../../../framework';

const {
    getServiceArea,
    getServiceLocationAddress,
    getBusinessAddress,
    getSelectedAddressType,
    ensureTravelOptionIfDistanceProvided,
    addressTypes,
    formFieldNames,
    fieldToPropertyMap,
    concatAddressProperties,
    travelOptions,
} = WebstoreAddressService;

const getFormDefaults = (webstore, includeOnline) => {
    const serviceLocationAddress = getServiceLocationAddress(webstore);
    const serviceArea = getServiceArea(webstore);
    const ffn = formFieldNames;

    let defaults = {
        [ffn.IsServiceTypeSelected]:
            !!webstore.PhysicalStore || (!webstore.hasCapacity ? !!webstore.Travels : false),
        [ffn.PhysicalStore]: !!webstore.PhysicalStore,
        [ffn.Travels]: !webstore.hasCapacity ? !!webstore.Travels : false,
        [ffn.LocationAddressType]: getSelectedAddressType(webstore),
        [ffn.ServiceAddressStreet]: serviceLocationAddress?.address1,
        [ffn.ServiceAddressStreet2]: serviceLocationAddress?.address2,
        [ffn.ServiceAddressCity]: serviceLocationAddress?.city,
        [ffn.ServiceAddressState]: serviceLocationAddress?.state,
        [ffn.ServiceAddressZipcode]: serviceLocationAddress?.zipCode,
        [ffn.DisplayStoreAddress]: !!webstore.DisplayStoreAddress,
        [ffn.TravelOption]: ensureTravelOptionIfDistanceProvided(webstore),
        [ffn.TravelDistance]: !webstore.hasCapacity ? serviceArea?.travelRadius : '',
        [ffn.ServiceAddress]: serviceLocationAddress?.address1
            ? concatAddressProperties({
                  address1: serviceLocationAddress.address1,
                  address2: serviceLocationAddress.address2,
                  city: serviceLocationAddress.city,
                  state: serviceLocationAddress.state,
                  zipCode: serviceLocationAddress.zipCode,
              })
            : null,
        [ffn.ServiceAddressIsBusinessAddress]: webstore.ServiceAddressIsBusinessAddress,
        [ffn.ServiceArea]:
            !!serviceArea?.city && !!serviceArea.state
                ? concatAddressProperties({
                      city: serviceArea.city,
                      state: serviceArea.state,
                  })
                : null,
        [ffn.ServiceAreaCity]: serviceArea?.city,
        [ffn.ServiceAreaState]: serviceArea?.state,
    };

    if (!!includeOnline) {
        defaults = {
            ...defaults,
            [ffn.OnlineStore]: webstore.OnlineStore,
            [ffn.ShippingRange]: webstore.ShippingRange,
        };
    }

    return defaults;
};

const mapBusinessAddressToServiceLocationAddress = (setValue, locationAddressType, webstore) => {
    if (locationAddressType === formFieldNames.BusinessAddress) {
        const businessAddress = getBusinessAddress(webstore);

        if (businessAddress) {
            setValue(formFieldNames.ServiceAddressStreet, businessAddress.address1);
            setValue(formFieldNames.ServiceAddressStreet2, businessAddress.address2);
            setValue(formFieldNames.ServiceAddressCity, businessAddress.city);
            setValue(formFieldNames.ServiceAddressState, businessAddress.state);
            setValue(formFieldNames.ServiceAddressZipcode, businessAddress.zipCode);
            setValue(
                formFieldNames.ServiceAddress,
                concatAddressProperties({
                    address1: businessAddress.address1,
                    address2: businessAddress.address2,
                    city: businessAddress.city,
                    state: businessAddress.state,
                    zipCode: businessAddress.zipCode,
                })
            );
        }
    }
};

const clearAddressTypeFields = (setValue) => {
    setValue(formFieldNames.ServiceAddress, null);
    setValue(formFieldNames.ServiceAddressIsBusinessAddress, false);
};

const clearServiceAddressFields = (setValue) => {
    setValue(formFieldNames.ServiceAddress, null);
    setValue(formFieldNames.ServiceAddressStreet, null);
    setValue(formFieldNames.ServiceAddressStreet2, null);
    setValue(formFieldNames.ServiceAddressCity, null);
    setValue(formFieldNames.ServiceAddressState, null);
    setValue(formFieldNames.ServiceAddressZipcode, null);
};

const setServiceCityState = (setValue, city, state) => {
    setValue(formFieldNames.ServiceAreaCity, city);
    setValue(formFieldNames.ServiceAreaState, state);
};

// #region Click handlers
const onlineStoreClickHandler = (setValue, onlineStore, setOnlineStore, travel, physicalStore) => {
    setOnlineStore(!onlineStore);
    setValue(formFieldNames.OnlineStore, !onlineStore);

    if (!travel && !physicalStore && onlineStore) {
        setValue(formFieldNames.IsServiceTypeSelected, false);
    } else {
        setValue(formFieldNames.IsServiceTypeSelected, true);
    }
};

const physicalStoreClickHandler = (
    setValue,
    physicalStore,
    setPhysicalStore,
    travel,
    canShowOnline,
    onlineStore
) => {
    clearAddressTypeFields(setValue);
    clearServiceAddressFields(setValue);

    if (!physicalStore) {
        setValue(formFieldNames.DisplayStoreAddress, true);
        setValue(formFieldNames.IsServiceTypeSelected, true);
        setValue(formFieldNames.ServiceAddressIsBusinessAddress, true);
        setValue(formFieldNames.PhysicalStore, true);
        setPhysicalStore(true);
    } else {
        if (canShowOnline) {
            setValue(formFieldNames.IsServiceTypeSelected, travel || onlineStore);
        } else {
            setValue(formFieldNames.IsServiceTypeSelected, travel);
        }

        setValue(formFieldNames.DisplayStoreAddress, false);
        setValue(formFieldNames.ServiceAddressIsBusinessAddress, false);
        setValue(formFieldNames.LocationAddressType, null);
        setValue(formFieldNames.PhysicalStore, false);
        setPhysicalStore(false);
    }
};

const physicalStoreOptionsClickHandler = (setValue, webstore, locationAddressType, getValues) => {
    if (locationAddressType === formFieldNames.BusinessAddress) {
        clearServiceAddressFields(setValue);

        if (getValues(formFieldNames.LocationAddressType) === addressTypes.BusinessAddress) {
            clearAddressTypeFields(setValue);
            setValue(formFieldNames.LocationAddressType, null);
            setValue(formFieldNames.ServiceAddressIsBusinessAddress, false);
        } else {
            mapBusinessAddressToServiceLocationAddress(setValue, locationAddressType, webstore);
            setValue(formFieldNames.ServiceAddressIsBusinessAddress, true);
            setValue(formFieldNames.PhysicalStore, true);
            setValue(formFieldNames.LocationAddressType, formFieldNames.BusinessAddress);
        }
    } else if (locationAddressType === formFieldNames.ServiceAddress) {
        clearServiceAddressFields(setValue);
        setValue(formFieldNames.ServiceAddressIsBusinessAddress, false);

        if (getValues(formFieldNames.LocationAddressType) === addressTypes.ServiceAddress) {
            clearAddressTypeFields(setValue);
            setValue(formFieldNames.LocationAddressType, null);
        } else {
            setValue(formFieldNames.LocationAddressType, formFieldNames.ServiceAddress);
            setValue(formFieldNames.PhysicalStore, true);
        }
    } else {
        clearAddressTypeFields(setValue);
    }
};

const travelsClickHandler = (
    setValue,
    travel,
    setTravel,
    physicalStore,
    setTravelOptionSelected,
    canShowOnline,
    onlineStore
) => {
    setValue(formFieldNames.TravelOption, null);
    setValue(formFieldNames.TravelDistance, null);
    setValue(formFieldNames.ServiceArea, null);
    setTravelOptionSelected(null);

    if (travel) {
        setTravel(false);
        setValue(formFieldNames.Travels, false);
        if (canShowOnline) {
            setValue(formFieldNames.IsServiceTypeSelected, physicalStore || onlineStore);
        } else {
            setValue(formFieldNames.IsServiceTypeSelected, physicalStore);
        }
    } else {
        setTravel(true);
        setValue(formFieldNames.Travels, true);
        setValue(formFieldNames.IsServiceTypeSelected, true);
    }
};

const localTravelClickHandler = (setValue, setTravelOptionSelected, setLocalTravel) => {
    setValue(formFieldNames.Travels, true);
    setValue(formFieldNames.TravelOption, travelOptions.Locally);
    setTravelOptionSelected(travelOptions.Locally);
    setLocalTravel(true);
};

const travelOptionsClickHandler = (
    setValue,
    optionSelected,
    setTravelOptionSelected,
    localTravelClickHandler,
    setLocalTravel
) => {
    setTravelOptionSelected(optionSelected);
    setValue(formFieldNames.TravelOption, optionSelected);
    setValue(formFieldNames.Travels, true);
    console.log(optionSelected);
    if (optionSelected === travelOptions.Locally) {
        setValue(formFieldNames.TravelOption, travelOptions.Locally);
        setValue(formFieldNames.Travels, true);
        setLocalTravel(true);
        setTravelOptionSelected(travelOptions.Locally);
    } else {
        setLocalTravel(false);
        setValue(formFieldNames.ServiceArea, null);
        setValue(formFieldNames.ServiceAreaCity, null);
        setValue(formFieldNames.ServiceAreaState, null);
        setValue(formFieldNames.TravelDistance, null);
    }
};

const localOptionClickHandler = (
    setValue,
    event,
    setLocalTravel,
    setTravelOptionSelected,
    localTravelClickHandler,
    physicalStore,
    mapFunctions,
    selectDistance,
    getValues
) => {
    if (getValues(formFieldNames.TravelOption) === travelOptions.Locally) {
        document.getElementById('TravelOption-locally')?.click();
        setValue(formFieldNames.TravelOption, travelOptions.Locally);
        setValue(formFieldNames.Travels, true);
        setLocalTravel(true);
        setTravelOptionSelected(travelOptions.Locally);
    } else {
        localTravelClickHandler(setValue, setTravelOptionSelected, setLocalTravel);
        setLocalTravel(false);
        setTravelOptionSelected(null);
        setValue(formFieldNames.TravelOption, null);
    }
    if (!physicalStore && mapFunctions) {
        selectDistance(event);
    }
};
// #endregion

export {
    getFormDefaults,
    fieldToPropertyMap,
    formFieldNames,
    mapBusinessAddressToServiceLocationAddress,
    clearAddressTypeFields,
    clearServiceAddressFields,
    onlineStoreClickHandler,
    physicalStoreClickHandler,
    physicalStoreOptionsClickHandler,
    travelsClickHandler,
    travelOptionsClickHandler,
    localTravelClickHandler,
    localOptionClickHandler,
    setServiceCityState,
    addressTypes,
};
