import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CityStateAutoComplete } from 'shared/components/CityStateAutoComplete/CityStateAutoComplete';
import { Dropdown, ToggleButtonGroup, ToggleButton, IconName } from 'dbi-pearl-ui-kit';
import { distanceOptions } from 'vendor/constants/select-options/distance';
import { FormRow, PearlError } from 'framework';
import { formFieldNames } from '../Functions/ServiceAreaForm.Functions';
import { TravelOptionsStyled } from './TravelOptions.styled';
import { WebstoreAddressService } from 'framework';

const TravelOptions = (props) => {
    const {
        mapFunctions,
        travelOptionSelected,
        travelOptionsClickHandler,
        localOptionClickHandler,
        physicalStore,
        localTravel,
        setServiceCityState,
    } = props;
    const { travelOptions } = WebstoreAddressService;
    const { t } = useTranslation();
    const { watch, setValue } = useFormContext();
    const [svcAreaQueryResult, setSvcAreaQueryResult] = useState('');
    const [hideServiceAreaAutoCompleteOptions, setHideServiceAreaAutoCompleteOptions] =
        useState(true);

    const { setMapZoom } = mapFunctions || {};
    const serviceAreaInput = watch(formFieldNames.ServiceArea);

    const mapZoomOnTravelChange = (optionSelected, setMapZoom) => {
        if (optionSelected === travelOptions.Globally) {
            setMapZoom(2);
        } else {
            setMapZoom(4);
        }
    };

    useEffect(() => {
        if (serviceAreaInput?.length > 0) {
            setHideServiceAreaAutoCompleteOptions(false);
            setValue(formFieldNames.ServiceArea, serviceAreaInput);
            setValue(formFieldNames.ServiceAreaCity, serviceAreaInput.split(',')?.[0]?.trim());
            setValue(formFieldNames.ServiceAreaState, serviceAreaInput.split(',')?.[1]?.trim());
        } else {
            setHideServiceAreaAutoCompleteOptions(true);
        }
    }, [serviceAreaInput]);

    useEffect(() => {
        if (svcAreaQueryResult) {
            setHideServiceAreaAutoCompleteOptions(false);
            const city = svcAreaQueryResult?.addressComponents?.find((comp) =>
                comp.types.includes('locality')
            )?.long_name;
            const state = svcAreaQueryResult?.addressComponents?.find((comp) =>
                comp.types.includes('administrative_area_level_1')
            )?.short_name;

            if (city && state) {
                setServiceCityState(setValue, city, state);
            }
        } else {
            setHideServiceAreaAutoCompleteOptions(true);
        }
    }, [svcAreaQueryResult]);

    const travelOptionsClick = (optionSelected) => {
        travelOptionsClickHandler(optionSelected);
        if (mapFunctions && !physicalStore) {
            mapZoomOnTravelChange(optionSelected, setMapZoom);
        }
    };

    return (
        <TravelOptionsStyled>
            <p className="option-question">
                <b>{t('Forms.ServiceArea.TravelSelection')}</b>
            </p>
            <p>{t('Forms.ServiceArea.TravelSelectionSubtitle')}</p>
            <FormRow
                columns={[
                    <div key={0}>
                        <ToggleButtonGroup
                            groupName={formFieldNames.TravelOption}
                            exclusive={true}
                            onChange={() => {}}
                            selectedGroupItems={[travelOptionSelected]}
                        >
                            <ToggleButton
                                value={travelOptions.Locally}
                                label={t('Forms.ServiceArea.Local')}
                                data-testid="travel-locally"
                                startIcon={IconName.MAP_MARKER_OUTLINE}
                                iconName={IconName.CHECKMARK}
                                onClick={() => travelOptionsClick(travelOptions.Locally)}
                            ></ToggleButton>
                            <ToggleButton
                                startIcon={IconName.AIRPLANE}
                                value={travelOptions.Nationally}
                                label={t('Forms.ServiceArea.National')}
                                iconName={IconName.CHECKMARK}
                                data-testid="travel-nationally"
                                onClick={() => travelOptionsClick(travelOptions.Nationally)}
                            />
                            <ToggleButton
                                startIcon={IconName.WEB}
                                value={travelOptions.Globally}
                                label={t('Forms.ServiceArea.Global')}
                                iconName={IconName.CHECKMARK}
                                data-testid="travel-globally"
                                onClick={() => travelOptionsClick(travelOptions.Globally)}
                            />
                        </ToggleButtonGroup>
                        <PearlError name={formFieldNames.TravelOption} />
                        {!!localTravel && (
                            <>
                                <FormRow
                                    columns={[
                                        <div data-testid="travel-distance" key={0}>
                                            <Dropdown
                                                providedLabel={t('Forms.ServiceArea.LocalDistance')}
                                                startIcon={IconName.MAP_MARKER_OUTLINE}
                                                formFieldName={formFieldNames.TravelDistance}
                                                onClick={(e) => localOptionClickHandler()} // can't pass localOptionClickHandler as obj as <Dropdown /> overwrites args
                                            >
                                                {distanceOptions.map((distance) => (
                                                    <option
                                                        key={distance.value}
                                                        value={distance.value}
                                                    >
                                                        {distance.label}
                                                    </option>
                                                ))}
                                            </Dropdown>
                                        </div>,
                                        <div key={1}>
                                            <CityStateAutoComplete
                                                setSearchResult={setSvcAreaQueryResult}
                                                hideOptions={hideServiceAreaAutoCompleteOptions}
                                                startIcon={null}
                                                fieldName={formFieldNames.ServiceArea}
                                            />
                                        </div>,
                                    ]}
                                />
                                <PearlError name={formFieldNames.ServiceArea} />
                            </>
                        )}
                        <PearlError name={formFieldNames.TravelDistance} />
                    </div>,
                ]}
            />
        </TravelOptionsStyled>
    );
};

export { TravelOptions };
