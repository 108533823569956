function getValueByPlanName(plans, tierName, key, frequency = 'month') {
    const sanitizedTierName = tierName.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
    const filteredPlans = plans.filter((plan) => plan.billingFrequency === frequency);
    const plan = filteredPlans.find((plan) => plan.planTier.toLowerCase() === sanitizedTierName);
    return plan && plan[key] ? plan[key] : undefined;
}

function formatPrice(value) {
    const numberValue = parseFloat(value);
    if (numberValue === 0) {
        return '0.00';
    }
    if (numberValue % 1 === 0) {
        return Math.floor(numberValue);
    } else {
        return numberValue.toFixed(2);
    }
}

function sortByPrice(arr, ascending = true) {
    return arr.sort((a, b) => {
        const priceA = parseFloat(a.value);
        const priceB = parseFloat(b.value);

        if (ascending) {
            return priceA - priceB;
        } else {
            return priceB - priceA;
        }
    });
}

export { getValueByPlanName, formatPrice, sortByPrice };
