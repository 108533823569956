const countryOptions = [
    { label: 'UNITED STATES', value: 'US' },
    { label: 'AUSTRALIA', value: 'AU' },
    { label: 'AUSTRIA', value: 'AT' },
    { label: 'BELGIUM', value: 'BE' },
    { label: 'BRAZIL', value: 'BR' },
    { label: 'BULGARIA', value: 'BG' },
    { label: 'CANADA', value: 'CA' },
    { label: 'COSTA RICA', value: 'CR' },
    { label: 'CROATIA', value: 'HR' },
    { label: 'CYRPUS', value: 'CY' },
    { label: 'CZECH REPUBLIC', value: 'CZ' },
    { label: 'COTE DLVOIRE', value: 'CI' },
    { label: 'DENMARK', value: 'DK' },
    { label: 'DOMINICAN REPUBLIC', value: 'DR' },
    { label: 'ESTONIA', value: 'EE' },
    { label: 'FINLAND', value: 'FI' },
    { label: 'FRANCE', value: 'FR' },
    { label: 'GERMANY', value: 'DE' },
    { label: 'GIBRALTAR', value: 'GI' },
    { label: 'GREECE', value: 'GR' },
    { label: 'GUATEMALA', value: 'GT' },
    { label: 'HONG KONG', value: 'HK' },
    { label: 'HUNGARY', value: 'HU' },
    { label: 'INDIA', value: 'IN' },
    { label: 'INDONESIA', value: 'ID' },
    { label: 'IRELAND', value: 'IE' },
    { label: 'ITALY', value: 'IT' },
    { label: 'JAPAN', value: 'JP' },
    { label: 'LATVIA', value: 'LV' },
    { label: 'LIECHTENSTEIN', value: 'LI' },
    { label: 'LITHUANIA', value: 'LT' },
    { label: 'LUXEMBOURG', value: 'LU' },
    { label: 'MALAYSIA', value: 'MY' },
    { label: 'MALTA', value: 'MT' },
    { label: 'MEXICO', value: 'MX' },
    { label: 'NETHERLANDS', value: 'NL' },
    { label: 'NEW ZEALAND', value: 'NW' },
    { label: 'NORWAY', value: 'NO' },
    { label: 'PERU', value: 'PE' },
    { label: 'PHILIPPINES', value: 'PH' },
    { label: 'POLAND', value: 'PL' },
    { label: 'PORTUGAL', value: 'PT' },
    { label: 'ROMANIA', value: 'RO' },
    { label: 'SENEGAL', value: 'SN' },
    { label: 'SINGAPORE', value: 'SG' },
    { label: 'SLOVAKIA', value: 'SK' },
    { label: 'SLOVENIA', value: 'SI' },
    { label: 'SPAIN', value: 'ES' },
    { label: 'SWEDEN', value: 'SE' },
    { label: 'SWITZERLAND', value: 'CH' },
    { label: 'THAILAND', value: 'TH' },
    { label: 'TRINIDAD & TOBAGO', value: 'TT' },
    { label: 'UNITED ARAB EMIRATES', value: 'AE' },
    { label: 'UNITED KINGDOM', value: 'GB' },
    { label: 'URUGUAY', value: 'UY' },
];
export default countryOptions;
