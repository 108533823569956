import styled from 'styled-components';

const WelcomeScreenContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    background-color: #fff;
    height: 280px;
    .checkmark {
        color: green;
    }
    .messageBox {
        text-align: center;
    }
    @media (max-width: 815px) {
        padding: 25px;
        margin-top: 100px;
    }
`;

const WelcomeTitle = styled.h4`
    margin-bottom: 24px;
    text-transform: none;
    color: #132122;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
`;

const WelcomeSubtitle = styled.h4`
    text-transform: none;
`;

export { WelcomeScreenContainer, WelcomeTitle, WelcomeSubtitle };
