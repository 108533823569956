import React from 'react';
import { WelcomeScreenContainer } from './SuccessPage.Styled';
import { PearlIcon } from 'dbi-pearl-ui-kit';

const SuccessPage = ({ children }) => {
    return (
        <WelcomeScreenContainer className="welcomeBannerContainer" data-testid="welcome-container">
            <PearlIcon
                className="checkmark"
                iconName="checkmark-circle"
                size={'70px'}
                data-testid="checkmark"
            />
            <div className="messageBox">{children}</div>
        </WelcomeScreenContainer>
    );
};

export default SuccessPage;
