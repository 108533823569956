import { APP_ROUTES } from 'shared/constants/navigation';
import { COMPLETE_STEP } from 'vendor/constants/WebstoreSetupSteps';

const RequireWebstoreComplete = (context) => {
    if (context.store.webstore.CurrentSetupStep !== COMPLETE_STEP) {
        if (context.store.appData.featureToggles.newVendor) {
            return APP_ROUTES.vendor.webstore.newsetup;
        }
        return APP_ROUTES.vendor.webstore.setup;
    }
    return 'next';
};

export default RequireWebstoreComplete;
