import { PearlApiClient, PearlApps } from 'framework';
import { unwrapResponse } from '../../../lib/apis/utils';

const stripePricingTableSrc = 'https://js.stripe.com/v3/pricing-table.js';

function StripeService() {
    const api = PearlApiClient(PearlApps.VENDOR);

    const createCustomerPortalSession = async (vendorId) => {
        const res = await api.post('stripe/create-customer-portal-session', {
            vendorId,
        });
        const unwrapped = unwrapResponse(res);
        return unwrapped.sessionUrl;
    };

    return {
        createCustomerPortalSession,
    };
}

function loadScript(src) {
    let script = document.createElement('script');

    script.type = 'text/javascript';

    if (script.readyState) {
        script.onreadystatechange = function () {
            if (script.readyState === 'loaded' || script.readyState === 'complete') {
                script.onreadystatechange = null;
            }
        };
    }

    script.src = src;
    document.getElementsByTagName('head')[0].appendChild(script);
}

const VendorStripePriceTable = (props) => {
    const { vendorId, vendorContactEmail } = props;

    loadScript(stripePricingTableSrc);
    console.log(
        '>> process.env.REACT_APP_STRIPE_PRICING_TABLE_ID: ',
        process.env.REACT_APP_STRIPE_PRICING_TABLE_ID
    );
    return (
        <stripe-pricing-table
            pricing-table-id={process.env.REACT_APP_STRIPE_PRICING_TABLE_ID}
            publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
            client-reference-id={`${vendorId}`}
            customer-email={vendorContactEmail}
            data-testid="stripe-table"
        />
    );
};

export { StripeService, VendorStripePriceTable, loadScript };
