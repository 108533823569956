import React, { useState } from 'react';
import styled from 'styled-components';
import { IconSizes, PearlIcon } from 'dbi-pearl-ui-kit';

const TooltipContainer = styled.div`
    position: relative;
    display: inline-block;
    .textStyle {
        font-family: 'Objektiv Mk2';
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 21px;
        padding: 16px;
        color: #132122;
    }
    .heading {
        margin: 0px;
        padding-top: 8px;
        color: #424d4e;
    }
    .infoStyle {
        cursor: pointer;
        color: black;
    }
    .closeIconStyle {
        padding-top: 10px;
        cursor: pointer;
    }
    @media (max-width: 815px) {
        display: flex;
        justify-content: end;
    }
`;

const TooltipContent = styled.div`
    visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
    opacity: ${({ visible }) => (visible ? 1 : 0)};
    transition: opacity 0.2s ease-in-out;
    width: ${({ width }) => (width ? `${width}px` : '343px')};
    height: ${({ height }) => (height ? `${height}px` : '293px')};
    background-color: #fff;
    color: #333;
    text-align: left;
    border-radius: 8px;
    box-shadow: 0px 6px 12px 0px rgba(19, 33, 34, 0.05);
    position: absolute;
    z-index: 1;
    bottom: ${({ bottom }) => (bottom ? `${bottom}%` : '76%')};
    left: ${({ left }) => (left ? `${left}%` : '-340%')};
    transform: translateX(-50%);

    @media (max-width: 815px) {
        position: absolute;
        bottom: ${({ bottomSmall }) => (bottomSmall ? `${bottomSmall}%` : '135%')};
        left: ${({ leftSmall }) => (leftSmall ? `${leftSmall}%` : '550%')};
        transform: translateX(-50%);
    }

    @media (max-width: 650px) {
        bottom: ${({ bottomSmall }) => (bottomSmall ? `${bottomSmall}%` : '72%')};
        left: ${({ leftSmall }) => (leftSmall ? `${leftSmall}%` : '72%')};
    }

    @media (max-width: 500px) {
        bottom: ${({ bottomSmall }) => (bottomSmall ? `${bottomSmall}%` : '72%')};
        left: ${({ leftSmall }) => (leftSmall ? `${leftSmall}%` : '56%')};
    }

    @media (max-width: 400px) {
        bottom: ${({ bottomSmall }) => (bottomSmall ? `${bottomSmall}%` : '72%')};
        left: ${({ leftSmall }) => (leftSmall ? `${leftSmall}%` : '50%')};
    }

    &::after {
        content: '';
        position: absolute;
        bottom: ${({ arrowBottom }) => (arrowBottom ? `${arrowBottom}%` : '-7%')};
        left: ${({ arrowLeft }) => (arrowLeft ? `${arrowLeft}%` : '86%')};
        transform: translateX(-50%);
        border-width: 12px;
        border-style: solid;
        border-color: #fff transparent transparent transparent;

        @media (max-width: 815px) {
            position: absolute;
            bottom: ${({ arrowBottomSmallscreen }) =>
                arrowBottomSmallscreen ? `${arrowBottomSmallscreen}%` : '-24%'};
            left: ${({ arrowLeftSmallscreen }) =>
                arrowLeftSmallscreen ? `${arrowLeftSmallscreen}%` : '15%'};
        }
    }
`;

const TooltipComponent = ({
    header,
    content,
    showCloseButton = true,
    hideDivider = false,
    width,
    height,
    bottom,
    left,
    bottomSmall,
    leftSmall,
    arrowBottom,
    arrowLeft,
    arrowBottomSmallscreen,
    arrowLeftSmallscreen,
    iconSize = IconSizes.SMD,
}) => {
    const [visible, setVisible] = useState(false);
    const showTooltip = () => setVisible(true);
    const hideTooltip = (e) => {
        e.stopPropagation();
        setVisible(false);
    };

    return (
        <TooltipContainer onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
            <PearlIcon iconName="info" className="infoStyle" size={iconSize} />
            <TooltipContent
                header={header}
                visible={visible}
                width={width}
                height={height}
                bottom={bottom}
                left={left}
                bottomSmall={bottomSmall}
                leftSmall={leftSmall}
                arrowBottom={arrowBottom}
                arrowLeft={arrowLeft}
                arrowBottomSmallscreen={arrowBottomSmallscreen}
                arrowLeftSmallscreen={arrowLeftSmallscreen}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: header ? '16px' : '0px',
                    }}
                >
                    {header && (
                        <h4 className="heading" style={{ textTransform: 'none' }}>
                            {header}
                        </h4>
                    )}
                    {showCloseButton && (
                        <PearlIcon
                            iconName="close"
                            onClick={hideTooltip}
                            size={IconSizes.MD}
                            className="closeIconStyle"
                        />
                    )}
                </div>
                {hideDivider && <hr style={{ margin: '0px' }} />}
                <div className="textStyle">{content}</div>
            </TooltipContent>
        </TooltipContainer>
    );
};

export default TooltipComponent;
