import * as Yup from 'yup';
import i18n from 'i18n';

const vendorBusinessSummaryFormSchema = Yup.object()
    .shape({
        BusinessSummary: Yup.string()
            .required(i18n.t('Forms.BusinessSummary.BusinessDescriptionRequired'))
            .nullable()
            .max(
                1500,
                i18n.t('Forms.Validation.CannotExceedCharacterLimit', {
                    characterLimit: '1500',
                })
            ),
    })
    .required();
export { vendorBusinessSummaryFormSchema };
