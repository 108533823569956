import styled from 'styled-components';

const VendorCheckoutFormStyled = styled.form`
    .formWrapper {
        .checkoutContainer {
            display: flex;
            align-items: flex-start;
            width: 100%;
            @media (max-width: 815px) {
                flex-direction: column;
                gap: 24px;
                margin-bottom: 80px;
            }

            & > div {
                width: 100%;
            }
        }
    }

    .label {
        font-weight: 700 !important;
    }
    .errorContainer {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    .error-text {
        color: red;
        font-size: 12px;
        // padding-top: 8px;
        padding-left: 16px;
        // padding-bottom: 8px;
        &:last-child {
            padding-bottom: 8px;
        }
    }
`;

export { VendorCheckoutFormStyled };
