import styled from 'styled-components';

const BasicsEditFormStyled = styled.section`
    .basics-category {
        position: relative;
        .mini-container {
            margin-top: 0;
        }
    }

    .visit-pearl-page {
        width: 100%;
    }
    .tooltipStyle {
        width: fit-content;
        position: absolute;
        display: flex;
        justify-content: flex-end;
        right: -32px;
        top: 16px;
        @media (max-width: 815px) {
            width: auto;
            position: sticky;
            padding-top: 12px;
            padding-right: 12px;
            z-index: 1000;
        }
    }
    .formRowStyle {
        @media (max-width: 815px) {
            margin-top: 10px !important;
        }
    }
`;

export { BasicsEditFormStyled };
