import apisauce from 'apisauce';
import { v4 } from 'uuid';

const callbackUrl = `${process.env.REACT_APP_BASE_URL}/authcallback`;
const oAuthClientId = process.env.REACT_APP_COGNITO_CLIENT_ID;
const oAuthUrl = process.env.REACT_APP_COGNITO_DOMAIN;
const oAuthScope = 'aws.cognito.signin.user.admin openid profile email phone';
const adminIdpName = 'DBIAzureAD';
console.log('>> process.env.REACT_APP_BASE_URL: ', process.env.REACT_APP_BASE_URL);

function toQs(input) {
    return Object.keys(input)
        .map((x) => encodeURIComponent(x) + '=' + encodeURIComponent(input[x]))
        .join('&');
}

function getSignInUrl(state) {
    const queryParams = {
        client_id: oAuthClientId,
        scope: oAuthScope,
        response_type: 'code',
        identity_provider: adminIdpName,
        state,
        redirect_uri: callbackUrl,
    };

    return `${oAuthUrl}/authorize?${toQs(queryParams)}`;
}

async function login() {
    const state = v4();
    const signInUrl = getSignInUrl(state);

    window.sessionStorage.setItem('authSource', 'cognito');
    window.sessionStorage.setItem('authState', state);

    window.location = signInUrl;
}

async function getToken(callbackState, code) {
    const localState = window.sessionStorage.getItem('authState');

    if (callbackState !== localState) {
        // TODO: SSO stuff
        throw new Error('Invalid state object returned from SSO');
    }

    const queryParams = {
        client_id: oAuthClientId,
        grant_type: 'authorization_code',
        redirect_uri: callbackUrl,
        code,
    };

    const client = apisauce.create({
        baseURL: oAuthUrl,
        timeout: 10000,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    });

    try {
        const { data } = await client.axiosInstance.post('/token', toQs(queryParams));
        return data;
    } catch {}

    return null;
}

async function refresh(refreshToken) {
    const queryParams = {
        client_id: oAuthClientId,
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
    };

    const client = apisauce.create({
        baseURL: oAuthUrl,
        timeout: 10000,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    });

    const { data } = await client.axiosInstance.post('/token', toQs(queryParams));

    return data;
}

const name = 'cognito';

export { login, getToken, refresh, name };
