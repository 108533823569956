import { PearlError } from 'framework';
import { TextAreaInput } from 'dbi-pearl-ui-kit';
import { BusinessSummarySectionStyled } from './BusinessSummarySection.styled';
import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';

const BusinessSummarySection = (props) => {
    const { watch, clearErrors } = useFormContext();

    const businessSummaryValue = watch('BusinessSummary');

    useEffect(() => {
        if (businessSummaryValue && businessSummaryValue.length > 0) {
            clearErrors('BusinessSummary');
        }
    }, [businessSummaryValue]);
    return (
        <BusinessSummarySectionStyled>
            <TextAreaInput formFieldName="BusinessSummary" maxLength={1500} {...props} />
            <PearlError name="BusinessSummary" />
        </BusinessSummarySectionStyled>
    );
};

export { BusinessSummarySection };
