import styled from 'styled-components';

const VendorPaymentSuccessFormStyled = styled.form`
    display: flex;
    .checkmark {
        width: 70px;
        height: 70px;
    }
`;

const Container = styled.div`
    max-width: 261px;
`;

const Email = styled.h6`
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 21px;
    margin-top: 0px;
`;

const Text = styled.p`
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 21px;
`;
export { VendorPaymentSuccessFormStyled, Container, Email, Text };
