import styled from 'styled-components';
import { BreakpointSizes } from 'dbi-pearl-ui-kit';

const ServiceAreaFormStyled = styled.div`
    display: flex;
    flex-direction: column;

    .display-false {
        display: none;
    }

    .p2 {
        padding-top: 18px;
    }

    .serviceOptions {
        display: flex;
        flex-direction: column;
        padding-bottom: 24px;
        padding-bottom: ${(props) => (props.showBottomBorder ? '24px' : '0')};
        border-bottom: ${(props) =>
            props.showBottomBorder
                ? `1.5px solid
        ${props.theme.colors.foreground.border}`
                : 'none'};
    }

    .service-option {
        margin-bottom: 0;
    }

    .option-question {
        margin-block: 0;
        margin-bottom: 8px;
        margin-top: 24px;
    }

    .checkboxGroup {
        display: flex;
        flex-direction: row;
        gap: 16px;
        padding-left: 0px;
        flex: 1 1 auto;
        margin-top: 24px;

        .option-title {
            margin: 0;
            font-weight: 700;
            text-transform: lowercase;
        }

        .option-content {
            margin: 0;
        }
    }

    .displayToggle {
        display: flex;
        align-items: center;
        border: solid 1px ${(props) => props.theme.colors.foreground.border};
        padding: 8px 16px;
        margin-top: 0;

        .toggle-subtitle,
        .toggletitle {
            margin: 0;
            padding-top: 2px;
        }
    }

    .address-type {
        label {
            display: inline-flex;
            justify-content: start;
            flex: unset;
            flex-wrap: nowrap;
            width: 100%;
            gap: 15px;
            align-items: center;

            &[aria-checked='true'] {
                border: 1px solid ${(props) => props.theme.colors.primary.base};
            }
        }
    }

    .radio-button {
        border: 1px solid ${(props) => props.theme.colors.foreground.base};
        border-radius: 50%;
        height: 24px;
        width: 24px;
        min-height: 24px;
        min-width: 24px;
    }

    .radio-button-selected {
        border: 7px solid ${(props) => props.theme.colors.primary.base};
        border-radius: 50%;
        height: 10px;
        width: 10px;
        min-height: 10px;
        min-width: 10px;
    }

    [role='group'] {
        flex-wrap: nowrap;
        gap: 16px;
    }

    [role='checkbox'] {
        margin: unset;
    }

    .feature-flag-container {
        display: flex;
        background-color: ${(props) => props.theme.colors.background.base};
        color: ${(props) => props.theme.colors.foreground.text};
        width: 100%;
        height: 100%;
        min-height: 39px;
        margin: 16px 0;

        .feature-flag {
            display: inline-flex;
            gap: 8px;
            align-items: center;
            padding: 0 16px;
        }

        p {
            margin: 0;
            padding-top: 3px;
            line-height: 28px;
        }
    }

    ${(props) => props.theme.pearlBreaks.down(BreakpointSizes.MD)} {
        .serviceOptions {
            flex-direction: column;
        }
        .checkboxGroup {
            flex-direction: column;
        }
        [role='group'] {
            flex-direction: column;
        }
        [role='checkbox'] {
            width: 100%;
        }
    }
`;

export { ServiceAreaFormStyled };
