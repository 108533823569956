/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { SolidButton, IconName, PearlIcon, IconSizes } from 'dbi-pearl-ui-kit';
import useWindowSize from 'vendor/hooks/useWindowSize';
import { useFormContext } from 'react-hook-form';
import {
    Tile,
    Title,
    Price,
    Description,
    Benefits,
    Benefit,
    PopularBadge,
    ToggleIcon,
    HiddenRadio,
    TileBody,
    Duration,
    PriceContainer,
    PlanInfo,
    Checkmark,
    BenefitItem,
} from './PickYourPlanTile.styled';

const PickYourPlanTile = ({
    title,
    price,
    value,
    altPrice,
    altPriceLink,
    description,
    benefits,
    isPopular,
    planId,
    formFieldName,
    onClick,
    isSelected,
    isOpen,
    onToggleClick,
    planInfoBtn,
    planInfo,
    period,
    planTier,
}) => {
    const { register, setValue } = useFormContext();
    const handleTileClick = () => {
        setValue(formFieldName, planTier);
        onClick();
    };
    const { width } = useWindowSize();

    return (
        <Tile
            onClick={handleTileClick}
            isPopular={isPopular}
            isSelected={isSelected}
            isOpen={isOpen}
        >
            <HiddenRadio
                type="radio"
                id={planId}
                data-testid={planId}
                name="plan-tier"
                value={planTier}
                defaultChecked={isSelected}
                readOnly
                {...register(formFieldName)}
            />
            {isPopular && <PopularBadge>{'Most Popular!'}</PopularBadge>}
            <TileBody>
                <Title isPopular={isPopular}>
                    {title}
                    <ToggleIcon data-testid="toggle-icon" onClick={onToggleClick}>
                        {isOpen ? (
                            <PearlIcon
                                className="minus"
                                iconName={IconName.MINUS}
                                size={IconSizes.MD}
                            />
                        ) : (
                            <PearlIcon
                                className="plus"
                                iconName={IconName.PLUS}
                                size={IconSizes.MD}
                            />
                        )}
                    </ToggleIcon>
                </Title>
                {(isOpen || width > 768) && (
                    <>
                        {/* <SolidButton
                            data-testid="trial-button"
                            onClick={onClick}
                            variant="primary"
                            className="saveButton"
                        >
                            {planInfoBtn}
                        </SolidButton> */}
                        {/* <Period>
                            <span>{altPrice}</span>
                            {altPriceLink && <TrialLink href="#">{altPriceLink}</TrialLink>}
                        </Period> */}
                        <PlanInfo>{planInfo}</PlanInfo>
                        <PriceContainer>
                            <Price>{`$${value}`}</Price>
                            <Duration>{`${period}*`}</Duration>
                        </PriceContainer>
                        {description && <Description> {description}</Description>}
                        <Benefits>
                            {benefits?.map((benefit, index) => (
                                <BenefitItem key={index}>
                                    <Checkmark></Checkmark>
                                    <Benefit>{benefit}</Benefit>
                                </BenefitItem>
                            ))}
                        </Benefits>
                    </>
                )}
            </TileBody>
        </Tile>
    );
};

PickYourPlanTile.propTypes = {
    title: PropTypes.string,
    price: PropTypes.string,
    value: PropTypes.string,
    altPrice: PropTypes.string,
    altPriceLink: PropTypes.string,
    period: PropTypes.string,
    description: PropTypes.string,
    benefits: PropTypes.arrayOf(PropTypes.string),
    isPopular: PropTypes.bool,
    planId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    formFieldName: PropTypes.string,
    onClick: PropTypes.func,
    isSelected: PropTypes.bool,
    isOpen: PropTypes.bool,
    onToggleClick: PropTypes.func,
    planInfoBtn: PropTypes.string,
    planInfo: PropTypes.string,
    planTier: PropTypes.string,
};

PickYourPlanTile.defaultProps = {
    isPopular: false,
};

export default PickYourPlanTile;
